import React, { Component } from "react";
import { Backdrop, CircularProgress } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import cnfg from "../tsConfig";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

class TsProfile extends Component {
  constructor(props) {
    super(props);
    console.log('TsProfile');
    console.log(props);
    this.state = {props:props,
      dialogOpen2: false,
        user:{
            userName: props.props.userName,
            displayName: props.props.displayName,
            id: props.props.id,
            email: props.props.email,
        }
    }
    this.changePswd = this.changePswd.bind(this);
  }
  changePswd(){
    var id = this.state.user.id;
    console.log('Change Password -> ' + id)
    this.setState({dialogOpen2: true})
  }
  handleClose2() {
    console.log("handle close 2");
    this.setState({ dialogOpen2: false });
  }
  render() {
    console.log(this.state)
    return (
      <>
      <div className="tsProfile">
        <h1>Profil</h1>
        <h2>Id: <strong>{this.state.user.id}</strong></h2>
        <h2>Namn: <strong>{this.state.user.displayName}</strong></h2>
        <h2>Användarnamn <strong>{this.state.user.userName}</strong></h2>
        <h2>E-post: <strong>{this.state.user.email}</strong></h2>
        <h2>Lösenord: <strong><button onClick={this.changePswd}>Uppdatering</button></strong></h2>        
      </div>
      <Dialog
      open={this.state.dialogOpen2}
      onClose={() => {this.handleClose2()}}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          formJson.id = this.state.user.id;
          console.log(formJson);
          axios
            .post(
              cnfg.api + "/users/update/",
              {
                data: formJson,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then((res) => {
              console.warn("data");
              console.warn(res.data);
              if (res.data.success) {
                // success
                console.log(res.data.message);
                alert(res.data.message);
                this.setState({ dialogOpen2:false });
                localStorage.setItem("dUsers", JSON.stringify(res.data.users));
              } else {
                // failed
                alert(res.data.message);
              }
            })
            .catch((e) => {
              console.error("Error Updating Password ");
              console.error(e);
              alert("Error Updating Password\n" + e);
            });
        },
      }}
    >
      <DialogTitle>Uppdatera lösenord ({this.state.user.id})</DialogTitle>
      <DialogContent>
        <TextField
          required
          margin='dense'
          id='password'
          name='password'
          label='Password'
          type='text'
          placeholder='Should Be Strong'
          fullWidth
          variant='standard'
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            this.handleClose2();
          }}
        >
          Annullera
        </Button>
        <Button type='submit'>
        Uppdatera lösenord
        </Button>
      </DialogActions>
    </Dialog>
    <Backdrop open={this.state.backDropState}>
          <CircularProgress color='primary' />
          {this.state.backDropStateMsg}
        </Backdrop>
    </>
    )
  }
}
export default TsProfile;

import React, { Component } from "react";
import packageJson from "../../package.json";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
class TsHeader extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="tsHeader">
        <div className='tsVersion'>V:{packageJson.version}</div>
        <img src='scandcliniclogo.webp' alt='logo' />
        <div className='tsMenu'>
          <a href="./">
            <MenuOpenIcon />
          </a>
        </div>
      </div>
    );
  }
}
export default TsHeader;

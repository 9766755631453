import './App.css';
import React, { Component } from 'react';
import { Home } from './pages/home.jsx';
import CacheBuster from './CacheBuster';
export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authCheck: true,
    };
  }
  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return (
            <div className='App'>
              <Home />
            </div>
          )
        }}
      </CacheBuster>
    )
  }
}
export default App;
import React, { Component } from "react";
import { Link } from "react-router-dom";
class Menu extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {props:props}
  }
  render() {
    return (
      <div className="tsMainMenu">
        <h1>Welcome <strong>{this.props.props.displayName}</strong></h1>
        <div className="tsMenu">
            <Link to='/profile'>Profil</Link>
            <Link to='/registrations'>Registrering av Patienter</Link>
            <Link to='/search'>Sparade Ansökningar</Link>
            <Link to='/users'>SARA Användare</Link>
            <Link to='/logout'>Logga ut</Link>
        </div>
      </div>
    )
  }
}
export default Menu;

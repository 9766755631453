import React, { Component } from "react";

import TsLoading from "../components/tsloading";
import LoginForm from "../components/loginform";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: localStorage.getItem("username") ?  localStorage.getItem("username") : '',
      password: localStorage.getItem("password") ?  localStorage.getItem("password") : '',
      token: localStorage.getItem("token"),
      handler:props.handler,
      loading: false,
    };
  } 

  render() {
    const { username, password, handler } = this.state;
    console.log('tejwani here - login')
    console.log(this.state)
    if(this.state.loading){
      return (
        <TsLoading txt="Loading..." /> 
      );

    }else{
      return (
        <LoginForm username={username} password={password} handler={handler}/>
      );

    }
  }
}

export default Login;

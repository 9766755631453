import React, { Component } from "react";
import { Backdrop, CircularProgress } from "@mui/material";

import Paper from "@mui/material/Paper";
// import PasswordIcon from "@mui/icons-material/Password";
import KeyIcon from "@mui/icons-material/Key";
import SecurityIcon from "@mui/icons-material/Security";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
// import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LoupeIcon from "@mui/icons-material/Loupe";
import DeleteIcon from "@mui/icons-material/Delete";
import cnfg from "../tsConfig";
import axios from "axios";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export class TsUsers extends Component {
  constructor(props) {
    super(props);
    console.log("TsUsers LOADING>...");
    this.getUsers();
    this.state = {
      backDropStateMsg: "Stand By...",
      backDropState: false,
      currentId: 0,
      currentName: "XXXXXX",
      dialogOpenNewUser: false,
      dialogOpenPswd: false,
      dialogOpenAdmin: false
    };
  }

  handleCloseNewUser() {
    console.log("handle close");
    this.setState({ dialogOpenNewUser: false });
  }
  handleClosePswd() {
    console.log("handle close 2");
    this.setState({ dialogOpenPswd: false });
  }
  handleCloseAdmin() {
    console.log("handle close 3");
    this.setState({ dialogOpenAdmin: false });
  }

  getUsers() {
    //   cnfg.api + "/users/",
    axios
      .post(
        cnfg.api + "/users/",
        { key: "value" },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        console.warn("data");
        console.warn(res.data);
        if (res.data.success) {
          this.setState({ users: res.data.users });
          localStorage.setItem("dUsers", JSON.stringify(res.data.users));
        } else {
          console.log("users: issue");
        }
        this.setState({ backDropState: false });
      })
      .catch((e) => {
        console.error("users: error in getting users");
        console.error(e);
        alert("users: error in getting users\n" + e);
        this.setState({ backDropState: false });
      });
  }
  handleOpen() {
    console.log("Open Dialog Box for new user");
    this.setState({ dialogOpenNewUser: true });
  }
  chgPswd(id, nm) {
    console.log("change password for id->" + id);
    // alert("WORK IN PROGRESS\n Chaneg Password for id->" + id);
    this.setState({ currentId: id, currentName: nm, dialogOpenPswd: true });
  }
  makeAdm(id, nm) {
    console.log("Make Admin id->" + id);
    // alert("WORK IN PROGRESS\n Chaneg Password for id->" + id);
    this.setState({ currentId: id, currentName: nm, dialogOpenAdmin: true });
  }
  del(id, nm) {
    console.log("delete id->" + id);
    if (!window.confirm("Are you sure you want to delete this user?")) {
      return;
    }
    if (!window.confirm("Are you DOUBLE sure you want to delete this user?")) {
      return;
    }
    if (
      !window.confirm(
        "LAST Chance\nThis cannot be undone\n\nAre you 100% sure you want to delete this user?"
      )
    ) {
      return;
    }

    axios
      .post(
        cnfg.api + "/users/delete/",
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.warn("data");
        console.warn(res.data);
        if (res.data.success) {
          // success
          console.log(res.data.message);
          alert(res.data.message);
          this.setState({ users: res.data.users, dialogOpenNewUser: false });
          localStorage.setItem("dUsers", JSON.stringify(res.data.users));
        } else {
          // failed
          alert(res.data.message);
        }
      })
      .catch((e) => {
        console.error("Adding new user: error in Adding new user ");
        console.error(e);
        alert("error in Adding new user\n" + e);
      });
  }
  render() {
    const dUsers = this.state.users
      ? this.state.users
      : JSON.parse(localStorage.getItem("dUsers"));
    const sa = this.state.sa
      ? this.state.sa
      : JSON.parse(localStorage.getItem("sa"));
    return (
      <div className='tsUsers'>
        <h1>
          Users
          {sa ? (
            <span>
              <button
                onClick={() => {
                  this.handleOpen();
                }}
              >
                <LoupeIcon /> Add
              </button>
            </span>
          ) : (
            ""
          )}
        </h1>
        <table>
          <thead>
            <tr>
              <td width='15%'>#</td>
              <td width='55%'>Name (login)</td>
              <td width='10%'>Del</td>
              <td width='10%'>Pswd</td>
              <td width='10%'>Adm</td>
            </tr>
          </thead>
          <tbody>
            {dUsers &&
              dUsers.map((row) => (
                <tr key={row.id}>
                  <td>{row.id}</td>
                  <td>
                    {row.sa == 1 ? (
                      <strong>
                        {row.displayName} ({row.userName})
                      </strong>
                    ) : (
                      `${row.displayName} (${row.userName})`
                    )}
                  </td>
                  <td>
                    {sa ? (
                      <button
                        onClick={() => {
                          this.del(row.id, row.displayName);
                        }}
                        title='Delete This User'
                      >
                        <DeleteIcon />
                      </button>
                    ) : (
                      "---"
                    )}
                  </td>
                  <td>
                    {sa ? (
                      <button
                        title='Change Password For User'
                        onClick={() => {
                          this.chgPswd(row.id, row.displayName);
                        }}
                      >
                        <KeyIcon />
                      </button>
                    ) : (
                      "---"
                    )}
                  </td>
                  <td>
                    {sa ? (
                      row.sa == 0 ? (
                        <button
                          title='Make Admin'
                          onClick={() => {
                            this.makeAdm(row.id, row.displayName);
                          }}
                        >
                          <AddModeratorIcon />
                        </button>
                      ) : (
                        <SecurityIcon />
                      )
                    ) : (
                      "---"
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <Dialog
          open={this.state.dialogOpenNewUser}
          onClose={() => {
            this.handleCloseNewUser();
          }}
          PaperProps={{
            component: "form",
            onSubmit: (event) => {
              event.preventDefault();
              this.setState({ backDropState: true });
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              const email = formJson.email;
              console.log(formJson);
              //   cnfg.api + "/users/new/",
              axios
                .post(
                  cnfg.api + "/users/new/",
                  {
                    data: formJson,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                )
                .then((res) => {
                  if (res.data.success) {
                    // success
                    console.log(res.data.message);
                    alert(res.data.message);
                    this.setState({
                      users: res.data.users,
                      dialogOpenNewUser: false,
                      backDropState: false,
                    });
                    localStorage.setItem(
                      "dUsers",
                      JSON.stringify(res.data.users)
                    );
                  } else {
                    // failed
                    alert(res.data.message);
                  }
                })
                .catch((e) => {
                  console.error("Adding new user: error in Adding new user ");
                  console.error(e);
                  alert("error in Adding new user\n" + e);
                  this.setState({ backDropState: false });
                });
            },
          }}
        >
          <DialogTitle>Add New User</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin='dense'
              id='username'
              name='username'
              label='UserId / Login'
              type='text'
              fullWidth
              variant='standard'
            />
            <TextField
              autoFocus
              required
              margin='dense'
              id='displayname'
              name='displayname'
              label='Real Name'
              placeholder='John Doe'
              type='text'
              fullWidth
              variant='standard'
            />
            <TextField
              required
              margin='dense'
              id='name'
              name='email'
              label='Email Address'
              type='email'
              fullWidth
              variant='standard'
            />
            <TextField
              required
              margin='dense'
              id='password'
              name='password'
              label='Password'
              type='text'
              placeholder='Should Be Strong'
              fullWidth
              variant='standard'
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleCloseNewUser();
              }}
            >
              Annullera
            </Button>
            <Button type='submit'>Submit New User</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.dialogOpenPswd}
          onClose={() => {
            this.handleClosePswd();
          }}
          PaperProps={{
            component: "form",
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              formJson.id = this.state.currentId;
              axios
                .post(
                  cnfg.api + "/users/update/",
                  {
                    data: formJson,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                )
                .then((res) => {
                  if (res.data.success) {
                    // success
                    console.log(res.data.message);
                    alert(res.data.message);
                    this.setState({
                      users: res.data.users,
                      dialogOpenPswd: false,
                      currentId: 0,
                    });
                    localStorage.setItem(
                      "dUsers",
                      JSON.stringify(res.data.users)
                    );
                  } else {
                    // failed
                    alert(res.data.message);
                  }
                })
                .catch((e) => {
                  console.error("Error Updating Password ");
                  console.error(e);
                  alert("Error Updating Password\n" + e);
                });
            },
          }}
        >
          <DialogTitle>
            Uppdatera lösenord ({this.state.currentName})
          </DialogTitle>
          <DialogContent>
            <TextField
              required
              margin='dense'
              id='password'
              name='password'
              label='Password'
              type='text'
              placeholder='Should Be Strong'
              fullWidth
              variant='standard'
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleClosePswd();
              }}
            >
              Annullera
            </Button>
            <Button type='submit'>Uppdatera lösenord</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.dialogOpenAdmin}
          onClose={() => {
            this.handleCloseAdmin();
          }}
          PaperProps={{
            component: "form",
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              formJson.id = this.state.currentId;
              axios
                .post(
                  cnfg.api + "/users/makeadmin/",
                  {
                    data: formJson,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                )
                .then((res) => {
                  if (res.data.success) {
                    // success
                    console.log(res.data.message);
                    alert(res.data.message);
                    this.setState({
                      users: res.data.users,
                      dialogOpenAdmin: false,
                      currentId: 0,
                    });
                    localStorage.setItem(
                      "dUsers",
                      JSON.stringify(res.data.users)
                    );
                  } else {
                    // failed
                    alert(res.data.message);
                  }
                })
                .catch((e) => {
                  console.error("Error Updating User as Admin ");
                  console.error(e);
                  alert("Error Updating User as Admin\n" + e);
                });
            },
          }}
        >
          <DialogTitle>Make {this.state.currentName} Admin</DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleCloseAdmin();
              }}
            >
              Annullera
            </Button>
            <Button type='submit'>
              Yes, Make {this.state.currentName} Admin
            </Button>
          </DialogActions>
        </Dialog>

        <Backdrop open={this.state.backDropState}>
          <CircularProgress color='primary' />
          {this.state.backDropStateMsg}
        </Backdrop>
      </div>
    );
  }
}

export default TsUsers;

import React, { Component } from "react";
import cnfg from "../tsConfig";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import { Backdrop, CircularProgress } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
export class Search extends Component {
  constructor(props) {
    super(props);
    console.log("Search LOADING...");
    // get folders
    this.state = {
      arrFiles: [],
      folderPath: "",
      querystring: "",
      backDropState: false,
      backDropStateMsg: "Hämta filer...",
    };
    this.getFolders();
    this.renderFolderSelect = this.renderFolderSelect.bind(this);
    this.getAll = this.getAll.bind(this);
    this.searchIt = this.searchIt.bind(this);
    this.clearFiles = this.clearFiles.bind(this);
  }

  getFolders() {
    console.log("getting folders");
    this.setState({ backDropState: true });
    axios
      .post(
        cnfg.api + "/search/folders/",
        { key: "value" },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        if (res.data.success) {
          this.setState({ users: res.data.folders });
          localStorage.setItem("folders", JSON.stringify(res.data.folders));
        } else {
          console.log("error in getting folders");
          console.log(res.data);
        }
        this.setState({ backDropState: false });
      })
      .catch((e) => {
        console.error("error in getting folders");
        console.error(e);
        alert("error in getting folders\n" + e);
        this.setState({ backDropState: false });
      });
  }
  filterData(data) {
    console.log("FILTER THIS ---------------------");
    console.log(data);
    var newData = [];
    var today = new Date();
    var year = today.getFullYear();
    var currMnth = today.getMonth() + 1;
    var prevMnth = currMnth > 1 ? currMnth - 1 : 12;
    var currDate = today.getDate();
    var prevDate = currDate - 21 +   30;
    for(var i = 0; i < data.length; i++){
      var j = data[i];
      var k = j.split('/');
      if(k.length > 3){
        if(this.isBetweenDates(parseInt(k[3]), parseInt(k[2]), currDate, currMnth, prevDate, prevMnth)){
          newData.push(j);
        }
      }else{
        newData.push(j);
      }

    }
    return newData;
  }


  isBetweenDates(thisDt, thisMnt, d1, m1, d2, m2) {
    console.log('thisDt:'+thisDt+" | thisMnt:" + thisMnt);
    console.log('d1:'+d1+" | m1:" + m1);
    console.log('d2:'+d2+" | m2:" + m2);
    
    if (thisMnt == m1) {
      console.log('thisMnt == m1');
      if (thisDt <= d1) {
        console.log('thisDt <= d1');
        return true;
      } else {
        console.log('!!! thisDt <= d1');
        return false;
      }
    } else if (thisMnt == m2) {
      console.log('thisMnt == m2');
      if (thisDt >= d2) {
        console.log('thisDt >= d2');
        return true;
      } else {
        console.log('!!! thisDt >= d2');
        return false;
      }
    } else {
      return false;
    }
  }
  keyValuePairFuncs(obj, prepend, res) {
    if (!obj) return res; // Added a null check for  Uncaught TypeError: Cannot convert undefined or null to object
    for (const [key, val] of Object.entries(obj)) {
      res.push(prepend + key);
      if (typeof val === "object") {
        this.keyValuePairFuncs(val, prepend + key + "/", res); // recursively call the function
      }
    }
    return res;
  }
  keyValuePairFuncsFiles(obj, prepend, res) {
    if (!obj) return res; // Added a null check for  Uncaught TypeError: Cannot convert undefined or null to object
    for (const [key, val] of Object.entries(obj)) {
      if (typeof val === "object") {
        this.keyValuePairFuncsFiles(val, prepend + key + "/", res); // recursively call the function
      } else if (typeof val === "string") {
        res.push(prepend + val);
      }
    }
    return res;
  }
  handleOnChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        console.log(
          "CHANGED...." + event.target.name + "//////////" + event.target.value
        );
        console.log(this.state);
      }
    );
  };
  clearFiles() {
    console.log("TIME TO CLEAR ALL FILES");
    // localStorage.removeItem("files");
    this.setState({ files: [] });
  }

  renderFiles() {
    var ts = this.state;
    const filesPath = localStorage.getItem("filesPath");
    const q = localStorage.getItem("q");
    var result1 = this.keyValuePairFuncsFiles(this.state.files, "", []);
    if (result1.length) {
      var html = (
        <>
          <ol>
            {result1.map(function (data) {
              const x = cnfg.docsUrl + filesPath + "/" + data;
              return (
                <li>
                  <a href={x} target='_blank'>
                    {filesPath}/{data} <OpenInNewIcon />
                  </a>
                </li>
              );
            })}
          </ol>
          <button className='clearResults' onClick={this.clearFiles}>
            Ny sökning
          </button>
        </>
      );
    } else {
      var html = (
        <ol>
          <li>No Result / Search Again</li>
        </ol>
      );
    }

    return html;
  }
  renderFolderSelect() {
    var ts = this.state;
    const arr = JSON.parse(localStorage.getItem("folders"));
    var res = this.keyValuePairFuncs(arr, "", []);
    var result = this.filterData(res.sort());
    var html = (
      <select name='folderPath' id='folderPath' onChange={this.handleOnChange}>
        <option value=''>Välj en mapp</option>
        {result.map(function (data) {
          if (ts.folderPath === data) {
            return (
              <option value={data} selected>
                {data}
              </option>
            );
          } else {
            return <option value={data}>{data}</option>;
          }
          // return <option value={data}>{data}</option>;
        })}
      </select>
    );
    return html;
  }
  getAll() {
    alert(
      "Work In Process\nThis Will fetch all pdfs files in folder\n" +
        this.state.folderPath
    );
  }
  searchIt() {
    // alert('Work In Process\nThis Will Search for ('+this.state.querystring+') in all pdfs files in folder\n'+this.state.folderPath);
    console.log("getting files");
    this.setState({ backDropState: true });
    axios
      .post(
        cnfg.api + "/search/files/",
        {
          q: this.state.querystring,
          path: this.state.folderPath,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        if (res.data.success) {
          this.setState({ files: res.data.files });
          // localStorage.setItem("files", JSON.stringify(res.data.files));
          localStorage.setItem("filesPath", this.state.folderPath);
          localStorage.setItem("q", this.state.querystring);
        } else {
          console.log("error in getting files");
          console.log(res.data);
        }
        this.setState({ backDropState: false });
      })
      .catch((e) => {
        console.error("error in getting folders");
        console.error(e);
        alert("error in getting files\n" + e);
        this.setState({ backDropState: false });
      });
  }
  render() {
    return (
      <div className='tsSearch'>
        <h1>Sparade Ansökningar</h1>
        <h5>1: Välj mapp</h5>
        {this.renderFolderSelect()}

        {/* <h5>2: Get All PDF's In <strong>{this.state.folderPath}</strong></h5>
        <button onClick={this.getAll}>Get All PDF's</button> */}
        <h5>
          2: Sök SSN/PNR in{" "}
          <strong>{this.state.folderPath ? this.state.folderPath : "/"}</strong>
        </h5>
        <input
          placeholder='Lämna tomt för att hämta alla filer'
          type='text'
          className='searchText'
          value={this.state.querystring}
          name='querystring'
          id='querystring'
          onChange={this.handleOnChange}
        />
        <button className='searchIt' onClick={this.searchIt}>
          <SearchIcon />
        </button>
        {this.renderFiles()}
        <Backdrop open={this.state.backDropState}>
          <CircularProgress color='primary' />
          {this.state.backDropStateMsg}
        </Backdrop>
      </div>
    );
  }
}

export default Search;

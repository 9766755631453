import React, { Component } from "react";
export class Logout extends Component {
  constructor(props) {
    super(props);
    localStorage.removeItem("token");
    console.log("LOGGING OUT...");
  }
  render() {
    return (
      <div className='loggedOut'>
        <h1>Utloggad</h1>
        <a href='./'>Logga in igen</a>
      </div>
    );
  }
}
export default Logout;

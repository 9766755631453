import React from "react";
import LinearProgress from '@mui/material/LinearProgress';
const TsLoading = ({ txt }) => (
  <header className="App-header">
   <LinearProgress />
    <span className="center tsLoading">{txt}</span>
  </header>
);

export default TsLoading;

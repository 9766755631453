import React, { Component } from "react";
import axios from "axios";
import TsLoading from "./tsloading";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Backdrop, CircularProgress } from "@mui/material";

import cnfg from "../tsConfig";
var api = cnfg.api;
class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: "",
      Password: "",
      loading: false,
    };
  }

  onFormSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });

    const siteUrl = api;
    const loginData = {
      username: this.state.Username,
      password: this.state.Password,
    };
    // add username and token into localstorage
    // localStorage.setItem("dealerUsername", loginData.username);
    // localStorage.setItem("dealerPassword", loginData.password);
    // this.setState(() => {
    axios
      .post(`${siteUrl}login/`, loginData)
      .then((res) => {
        if (res.data.success) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("sa", res.data.sa);
          localStorage.setItem("email", res.data.email);
          localStorage.setItem("id", res.data.id);
          localStorage.setItem("userName", res.data.userName);
          localStorage.setItem("displayName", res.data.displayName);
          this.props.handler([true, res.data.userName, res.data.displayName, res.data.id, res.data.email]);
        } else {
          ["token"].forEach((k) => localStorage.removeItem(k));
          alert(res.data.message);
          this.setState({
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
    // });
  };

  handleOnChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  render() {
    return this.state.loading ? (
      <>
        {/* <TsLoading txt='Kontrollerar inloggning...' /> */}
        <Backdrop open={this.state.loading}>
          <CircularProgress color='primary' />
          Kontrollerar inloggning...
        </Backdrop>
      </>
    ) : (
      <>
        <form onSubmit={this.onFormSubmit} className='tsLoginForm'>
          <h1>Logga in</h1>
          <hr />
          Användarnamn:
          <TextField
            // variant="outlined"
            margin='normal'
            required
            fullWidth
            id='Username'
            placeholder='Username'
            name='Username'
            autoComplete='Username'
            value={this.state.Username}
            onChange={this.handleOnChange}
            InputLabelProps={{
              shrink: true,
            }}
            autoFocus
          />
          <br />
          Lösenord:
          <TextField
            // variant="outlined"
            // margin='normal'
            InputLabelProps={{
              shrink: true,
            }}
            required
            fullWidth
            name='Password'
            placeholder='Password'
            type='password'
            id='Password'
            autoComplete='current-password'
            value={this.state.Password}
            onChange={this.handleOnChange}
          />
          <br />
          <Button type='submit' className='submitBtn'>
          Logga in
          </Button>
        </form>
      </>
    );
  }
}

export default LoginForm;

import React, { Component } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

// import { Download } from "@mui/icons-material";
import SignatureCanvas from "react-signature-canvas";
// var base = "https://sara.scandclinic.com/v9/api/";
var base = "https://sara.scandclinic.com/api/";
// var base = 'http://localhost/react/scand-clinic/api/';

export class Registrations extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      authCheck: true,
      dialogOpen2: false,
      username: "",
      displayName: "",
      ssn: "",
      data: {},
      clinic: "SCANDCLINIC PLAYA FLAMENCA",
      res: {},
      step: 0,
      backDropState: false,
      backDropStateMsg: "Kontaktar Server...",
      pdfLink: "",
      signBlob: "",
    };
  }
  handleChange = (e) => {
    let s = this.state;
    s[e.target.name] = e.target.value;
    this.setState(s);
    console.log(this.state);
  };
  getDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    let date = today.getDate();
    if (date < 10) {
      date = "0" + date;
    }
    return `${year}-${month}-${date}`;
  }
  showModalSign = (e) => {
    console.log("showModalSign START");
    this.setState({ 
      signBlob: "",
      dialogOpen2: true 
    });
  };
  getPdf = (e) => {
    this.setState({ backDropState: true });
    console.log("getinfo pdf");
    let url = base + "genpdf.php?r=" + Math.random();
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "ssn": this.state.ssn,
        "clinic": this.state.clinic,
        "name1": this.state.res.result.name1,
        "name2": this.state.res.result.name2,
        "name3": this.state.res.result.name3,
        "phoneNumbers":
          this.state.res.result.phoneNumbers &&
          this.state.res.result.phoneNumbers.length
            ? this.state.res.result.phoneNumbers[0]["phoneNumber"]
            : "",
        "street": this.state.res.result.street,
        "postalcode": this.state.res.result.postalcode,
        "city": this.state.res.result.city,
        "signBlob": this.state.signBlob,
        "date": this.getDate(),
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          backDropState: false,
          pdfLink: result.pdf,
          signBlob: null,
        });
      })
      .catch((error) => {
        console.error("Error:");
        console.error(error);
        alert("ERROR\n" + error);
        this.setState({ backDropState: false });
      });
  };
  getInfo = (e) => {
    if (this.state.ssn.length != 13) {
      alert(
        "Check the SSN entered\nShould be 13 Characters in format YYYYMMDD-NNNN\n>>>"+this.state.ssn+"<<< is "+this.state.ssn.length+" characters\nDouble check for extra spaces at begin/end"
      );
      return;
    }
    this.setState({ backDropState: true, res: "Fetching Data..." });

    const url =
      "https://sara.scandclinic.com/api/?ssn=" +
      this.state.ssn.replace("-", "") +
      "&r=" +
      Math.random();
    fetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          this.setState({ backDropState: false, res: result, step: 1 });
        } else {
          alert(
            result.message + "\n(Response Code: " + result.responseCode + ")"
          );
          this.setState({ backDropState: false, res: result });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.setState({ backDropState: false, res: error });
      });
  };
  restart = (e) => {
    console.log("restarting....");
    this.setState({
      step: 0,
      pdfLink: "",
    });
    // this.setState({
    //   ssn: '',
    //   data: {},
    //   clinic: 'SCANDCLINIC GUARDAMAR',
    //   res: 'Result Here',
    //   step: 0,
    //   pdfLink: ""
    // })
  };
  showLinkOrForm() {
    if (this.state.pdfLink) {
      return (
        <div className='resultPdf'>
          <h1>Registrering av Patienter</h1>
          <h2>
            <DoneIcon /> 1. Ange personnummer
          </h2>
          <h2>
            <DoneIcon /> 2. Patientinformation
          </h2>
          <h2>3. View or Download the PDF</h2>
          <a href={this.state.pdfLink} target='_blank'>
            View / Download PDF
          </a>
          <input
            type='submit'
            value='Start Over'
            className='pdfBtn'
            onClick={this.restart}
          />
          <Link to='/search'>Sparade Ansökningar</Link>
        </div>
      );
    } else {
      let renderSign = <></>;
      if (!this.state.signBlob) {
        renderSign = (
          <>
            <button onClick={this.showModalSign}>Sign</button>
            <Dialog
              open={this.state.dialogOpen2}
              onClose={() => {
                this.clearAndClose();
              }}
              PaperProps={{
                component: "form",
                onSubmit: (event) => {
                  event.preventDefault();
                  const formData = new FormData(event.currentTarget);
                  const formJson = Object.fromEntries(formData.entries());
                  console.log("TODO - 314");
                  console.log(formJson);
                },
              }}
            >
              <DialogTitle>SIGN IN BOX BELOW</DialogTitle>
              <DialogContent>
                <SignatureCanvas
                  penColor='#0000cc'
                  canvasProps={{
                    className: "signPad",
                    // width: 420, 
                    // height: 140,
                  }}
                  ref={(ref) => {
                    this.sigPad = ref;
                    if (ref) {
                      this.populateCanvas(ref);
                    }
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.clear();
                  }}
                >
                  Clear Sign
                </Button>
                <Button
                  onClick={() => {
                    this.trim();
                  }}
                >
                  Save Sign
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
        //   renderSign = (
        //     <>
        //       <h1>Patientsignatur</h1>
        //       <div className='signBox'>
        //         <SignatureCanvas
        //           penColor='#0000cc'
        //           canvasProps={{
        //             className: "signPad",
        //           }}
        //           ref={(ref) => {
        //             this.sigPad = ref;
        //             if (ref) {
        //               this.populateCanvas(ref);
        //             }
        //           }}
        //         />
        //       </div>
        //       <div className='twoButtons'>
        //         <button onClick={this.clear}>Clear Sign Area</button>
        //         <button onClick={this.trim}>Save Sign</button>
        //       </div>
        //     </>
        //   );
      } else {
        renderSign = (
          <>
            <h1>Submit signature</h1>
            <div
              className='tsSign'
              style={{ backgroundImage: `url(${this.state.signBlob})` }}
            ></div>

            <input
              type='submit'
              value='Skapa PDF'
              className='pdfBtn'
              onClick={this.getPdf}
              />
              <button onClick={this.showModalSign}>Delete / Re-Sign</button>
          </>
        );
      }
      return (
        <>
          <h1>Registrering av Patienter</h1>
          <h2>
            <DoneIcon /> 1. Ange personnummer
          </h2>
          <h2>2. Patientinformation</h2>
          <ul>
            <li>
              Förnamn: <strong>{this.state.res.result.name1}</strong>
            </li>
            <li>
              Efternamn: <strong>{this.state.res.result.name3}</strong>
            </li>
            <li>
              Adress: <strong>{this.state.res.result.street}</strong>
            </li>
            <li>
              Postnummer: <strong>{this.state.res.result.postalcode}</strong>{" "}
            </li>
            <li>
              Postort: <strong>{this.state.res.result.city}</strong>
            </li>
          </ul>

          <h2>Välj Klinik</h2>
          <select
            name='clinic'
            value={this.state.clinic}
            onChange={this.handleChange}
          >
            <option value='SCANDCLINIC PLAYA FLAMENCA'>PLAYA FLAMENCA</option>
            <option value='SCANDCLINIC COSTA DEL SOL'>COSTA DEL SOL </option>
            <option value='SCANDCLINIC GUARDAMAR'>GUARDAMAR </option>
          </select>

          {renderSign}
          <Backdrop open={this.state.backDropState}>
            <CircularProgress color='primary' />
            {this.state.backDropStateMsg}
          </Backdrop>
        </>
      );
    }
  }

  sigPad = {};
  populateCanvas(ref) {
    var gc = ref.getCanvas();
    var ctx = gc.getContext("2d");
  }
  clearAndClose = () => {
    // this.sigPad.clear();
    // this.setState({
    //   dialogOpen2: false
    // })
  };
  clear = () => {
    this.sigPad.clear();
    this.populateCanvas(this.sigPad);
  };
  trim = () => {
    var x = this.sigPad.getTrimmedCanvas().toDataURL("image/png");
    // var x = this.sigPad.getCanvas().toDataURL("image/png");
    console.log('dev here')
    var img = document.createElement('img');
    img.src = x;
    img.onload = () => {
      var w = img.width;
      var h = img.height;
      console.log("NEW IMAGE width", w);
      console.log("NEW IMAGE height: ", h);
      if(w < 20 || h < 20 ){
        alert("Please make sure signature is Correct\nAnd good to save\n\nLooks too small at the moment ("+w+"px x "+h+"px)");
      }else{
        this.setState({
          signBlob: x,
          dialogOpen2: false,
          // signBlob2: this.sigPad.toDataURL("image/png"),
        });
      }
    }


    /*
    this.setState({
      signBlob: this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
      dialogOpen2: false,
      // signBlob2: this.sigPad.toDataURL("image/png"),
    });
    */
  };
  // resign = () => {
  //   this.setState({
  //     signBlob: null,
  //   });
  //   // this.sigPad.clear();
  //   // this.populateCanvas(this.sigPad);
  // };
  render() {
    switch (this.state.step) {
      // case 0:
      case 1:
        // const a = JSON.stringify(this.state.res, undefined, 1);
        return <div className='tsRegistrations'>{this.showLinkOrForm()}</div>;
      // case 0:
      // case 1:
      default:
        return (
          <div className='tsRegistrations'>
            <h1>Registrering av Patienter</h1>
            <h2>1. Ange personnummer</h2>
            <input
              type='text'
              name='ssn'
              value={this.state.ssn}
              onChange={this.handleChange}
              placeholder='YYYYMMDD-NNNN'
            />
            <br />

            <input
              type='submit'
              value='Hämta adressuppgifter'
              className='submitBtn'
              onClick={this.getInfo}
            />

            <Backdrop open={this.state.backDropState}>
              <CircularProgress color='primary' />
              {this.state.backDropStateMsg}
            </Backdrop>
          </div>
        );
      // break;
    }
  }
}
export default Registrations;

import React, { Component } from "react";
import Login from "./login";
import axios from "axios";
import cnfg from "../tsConfig";
import Menu from "./menu";
import Registrations from "./registrations";
import Search from "./search";
import TsUsers from "./tsusers";
import TsProfile from "./tsprofile";
import Logout from "./logout";
import TsHeader from "../components/tsheader";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      authCheck: true,
      userName: "",
      displayName: "",
      id: "",
      email: "",
      sa: false,
    };
    if (localStorage.getItem("token")) {
      // check if authorized
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      const bodyParameters = {
        key: "value",
      };

      axios
        .post(cnfg.api + "protected/", bodyParameters, config)
        .then((res) => {
          if (res.data.success) {
            // authorized
            this.setLoggedIn(res);
          } else {
            //unauthorized, delete local cache and show login screen
            localStorage.removeItem("token");
          }
          this.setState({ authCheck: false });
        })
        .catch((e) => {
          console.error(e);
          localStorage.removeItem("token");
          this.setState({ authCheck: false });
        });
    } else {
      this.state.authCheck = false;
    }
  }
  setLoggedIn(res) {
    this.setState({
      loggedIn: true,
      id: res.data.id,
      userName: res.data.userName,
      displayName: res.data.displayName,
      email: res.data.email,
      sa: res.data.sa,
    });
  }
  render() {
    if (this.state.loggedIn) {
      return (
        <>
          <TsHeader />
          <Router>
            <Routes>
              <Route path='/' exact element={<Menu props={this.state} />} />
              <Route path='/registrations' exact element={<Registrations />} />
              <Route path='/search' exact element={<Search />} />
              <Route path='/users' exact element={<TsUsers />} />
              <Route path='/profile' exact element={<TsProfile  props={this.state} />} />
              {/* <Route
                path='/login'
                exact
                element={
                  <Login
                    handler={(p) => {
                      this.setState({
                        loggedIn: p[0],
                        userName: p[1],
                        displayName: p[2],
                      });
                    }}
                  />
                }
              /> */}
              <Route
                path='/logout'
                exact
                element={<Logout props={this.state} />}
              />
            </Routes>
          </Router>
          {/* <Menu props={this.state} /> */}
        </>
      );
    } else {
      if (this.state.authCheck) {
        return (
          <>
            <TsHeader />
            Stand by... Checking....
          </>
        );
      } else {
        return (
          <>
            <TsHeader />
            <Login
              handler={(p) => {
                this.setState({
                  loggedIn: p[0],
                  userName: p[1],
                  displayName: p[2],
                  id: p[3],
                  email: p[4],
                });
              }}
            />
          </>
        );
      }
    }
  }
}
export default Home;

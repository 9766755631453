var baseUrl = "https://sara.scandclinic.com/"; // v9/ 
// var baseUrl = "http://localhost/react/scand-clinic/"; 
var docsUrl = baseUrl+"docs/";
var tsApi = baseUrl + "api/";

var cnfg = {
    api: tsApi,
    docsUrl :docsUrl,
    baseUrl: baseUrl,
}; 
export default cnfg;